import React from 'react'
import { graphql } from 'gatsby'
import img from '../../static/images/bg_1.jpg';
import PageHeader from '../components/PageHeader'
import Content from '../components/Content'
import Layout from '../components/Layout'
import Image from '../components/Image';
import Accordion from '../components/Accordion';
import care_img from '../../static/images/FullSizeRender.jpeg';
import amy from '../../static/images/newport.jpg';
import nadar from '../../static/images/nadar.jpg';
import './AboutPage.css';

// Export Template for use in CMS preview
export const AboutPageTemplate = ({ title, subtitle, featuredImage, employeeList, employeeList2, accordion, accordion2, body }) => (
  <main className="Home">
    <PageHeader
      large
      title={title}
      subtitle={subtitle}
      backgroundImage={featuredImage}
    />

    <section className="section">
      <div className="container">
      <div className="container imgskinnier">
     <Image alt="Dr. Nadar" src={nadar} resolutions="small" className="care-group"/>
     </div>
        <Content source={body} />
        <h2>Camp Hill Staff</h2>
        <Image alt="Capital Area Research Team" src={care_img} resolutions="small" className="care-group"/>
        <Content source={employeeList} />
        <div className="container">
          <Accordion items={accordion} />
        </div>
        <h2>Newport Staff</h2>
        <Image alt="Capital Area Research Team, Newport" src={amy} resolutions="small" className="care-group"/>
        <Content source={employeeList2} />
        <div className="container">
            <Accordion items={accordion2} />
        </div>
      </div>
    </section>

  </main>
)

// Export Default HomePage for front-end
const AboutPage = ({ data: { page } }) => (
  <Layout meta={page.frontmatter.meta || false}>
    <AboutPageTemplate {...page} {...page.frontmatter} body={page.html} />
  </Layout>
)

export default AboutPage

export const pageQuery = graphql`
  ## Query for HomePage data
  ## Use GraphiQL interface (http://localhost:8000/___graphql)
  ## $id is processed via gatsby-node.js
  ## query name must be unique to this file
  query AboutPage($id: String!) {
    page: markdownRemark(id: { eq: $id }) {
      ...Meta
      html
      frontmatter {
        title
        subtitle
        featuredImage
        employeeList
        employeeList2
        accordion {
          title
          description
        }
        accordion2 {
          title
          description
        }
      }
    }
  }
`
